// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCampaignFieldMappingsRequestViewModel from '../../viewModel/request/FieldMapping/GetCampaignFieldMappingsRequestViewModel';
import CampaignMappingFieldResourcePagingResultViewModel from '../../viewModel/resource/CampaignMappingFieldResourcePagingResultViewModel';
import CampaignMappingFieldResourceViewModel from '../../viewModel/resource/CampaignMappingFieldResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getCampaignFieldMappings(request: GetCampaignFieldMappingsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-field-mappings'] ?? 'core/integration/FieldMapping/CampaignFieldMappings';
    const response = await service.get<any>(endpointPath, {
      query: {
        appSystemId: requestDTO.appSystemId,
        fieldId: requestDTO.fieldId,
        fieldValue: requestDTO.fieldValue,
        otherAppSystemId: requestDTO.otherAppSystemId,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new CampaignMappingFieldResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getCampaignFieldMappingById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-field-mapping-by-id'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new CampaignMappingFieldResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateCampaignFieldMapping(id: number, model: CampaignMappingFieldResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-campaign-field-mapping'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async deleteCampaignFieldMapping(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-campaign-field-mapping'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async createCampaignFieldMapping(model: CampaignMappingFieldResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-campaign-field-mapping'] ?? 'core/integration/FieldMapping';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
