// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetContactIdMappingsRequestViewModel from '../../viewModel/request/ContactIdMappings/GetContactIdMappingsRequestViewModel';
import ContactIdMappingListResourcePagingResultViewModel from '../../viewModel/resource/ContactIdMappingListResourcePagingResultViewModel';
import ContactIdMappingResourceViewModel from '../../viewModel/resource/ContactIdMappingResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getContactIdMappings(request: GetContactIdMappingsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-contact-id-mappings'] ?? 'core/integration/ContactIdMappings';
    const response = await service.get<any>(endpointPath, {
      query: {
        foreignAppSystemIdCriteria: requestDTO.foreignAppSystemIdCriteria,
        crmStatusIdCriteria: requestDTO.crmStatusIdCriteria,
        mappingStatusIdCriteria: requestDTO.mappingStatusIdCriteria,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ContactIdMappingListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getContactIdMappingById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-contact-id-mapping-by-id'] ?? 'core/integration/ContactIdMappings/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ContactIdMappingResourceViewModel().fromDTO(dto);
  },
});
