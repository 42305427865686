import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DocumentsApi from '../generated/api/Documents';
import UploadDocumentRequestViewModel from '../viewModel/request/Documents/UploadDocumentRequestViewModel';

export default (service: Servicelayer) => ({
  ...DocumentsApi(service),

  async downloadImage(id: number) {
    const imageBlob = await this.downloadDocument(id);
    return new Blob([imageBlob], { type: 'image/*' });
  },

  async uploadMultipleDocuments(documents: File[] | Blob[]) {
    const allPromises = [] as Promise<number | undefined>[];
    documents.forEach((document) => {
      const request = new UploadDocumentRequestViewModel();
      request.uploadFile = document as File;
      const promise = service.api.documents.uploadDocument(request);
      allPromises.push(promise);
    });
    const allIds = await Promise.all(allPromises) as number[];
    return allIds;
  },

});
