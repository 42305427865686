import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core/src';
import App from '@glittr/frontend-core/src/app.vue';
import router from './router';
import localization from './localization';
import services from './services';
import ilLanguageSelection from './components/il-language-selection.vue';
import ilSafetyhookLookup from './components/il-safetyhook-lookup.vue';
import ilWizard from './components/processes/il-wizard.vue';
import ilWizardStep from './components/processes/il-wizard-step.vue';
import shareGuard from './guards/shareGuard';

Vue.config.productionTip = false;
const APP_ID = 'app';
// Core entry point
Vue.use(core, {
  appId: APP_ID,
  noMount: true,
  translations: localization.translations,
  router,
  Vue,
} as CoreOptions);
// APP____________________________________________________________
// STYLES
require('./assets/styles/main.scss');
// PLUGINS
Vue.use(services);
// COMPONENTS
Vue.component('IlLanguageSelection', ilLanguageSelection);
Vue.component('IlWizard', ilWizard);
Vue.component('IlWizardStep', ilWizardStep);
Vue.component('IlSafetyhookLookup', ilSafetyhookLookup);
// GUARDS
Vue.$guards.push(shareGuard);
if (Vue.$guards.guards.length !== 3) {
  // TODO: Possibly be able to define order when pushing guards
  Vue.$log.error('Guard setup has changed! The shareGuard has to come before the LoginGuard or the mobile part of the application won\'t work!');
}
// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});
// _______________________________________________________________
const app = new Vue({
  router: Vue.$router,
  render: (h) => h(App),
});
const mountDiv = document.createElement('div');
const appNode = document.getElementById(APP_ID);
if (appNode === null || appNode === undefined) {
  console.error(`Unable to mount app, no node with id '${APP_ID}' was found`);
} else {
  appNode.style.height = '100%';
  const foo = appNode.insertBefore(mountDiv, null);
  app.$mount(mountDiv);
}
require('./store');

declare module '@core/plugins/config/config' {
 interface ConfigType {
 // add custom config props here
 'siha-qrcode-enabled': boolean,
 'siha-qrcode-generator-url': string
 }
}
