import SiHaDocumentationResourceModel from '../../generated/types/model/resource/SiHaDocumentationResourceModel';

export default class SiHaDocumentationResourceViewModel extends SiHaDocumentationResourceModel {
  protected transformToDTO() {
    this.hasSavedSafetyHooks = (this.safetyHookIds ?? []).length > 0;
  }
  protected transformFromDTO() {
    this.hasSavedSafetyHooks = (this.safetyHookIds ?? []).length > 0;
  }

  hasSavedSafetyHooks = false;

  get isOnSiteSiHaDocumentationAvailable() {
    // Status Abgeschlossen
    return this.siHaDocumentationStatus !== 5;
  }

  get displayArchiveDocumentationDisclaimer() {
    // (Komplett oder Abgeschlossen) && Datum gesetzt
    return (this.siHaDocumentationStatus === 5 || this.siHaDocumentationStatus === 4) && !!this.nextInspectionDueDate;
  }

  get projectCaption() {
    return `${this.title} - ${this.addressLine}, ${this.postalCode} ${this.city}`;
  }
}
