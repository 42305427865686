import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import SiHaDocumentationApi from '../generated/api/SiHaDocumentation';

export default (service: Servicelayer) => ({
  ...SiHaDocumentationApi(service),

  // Add custom calls here

});
