// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSafetyHookLookupRequestViewModel from '../../viewModel/request/SiHaSafetyHook/GetSafetyHookLookupRequestViewModel';
import SafetyHookResourceListResultViewModel from '../../viewModel/resource/SafetyHookResourceListResultViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';
import SafetyHookResourceViewModel from '../../viewModel/resource/SafetyHookResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getSafetyHookList(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-hook-list'] ?? 'siha/SafetyHook';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SafetyHookResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSafetyHookLookup(request: GetSafetyHookLookupRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-hook-lookup'] ?? 'siha/SafetyHook/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        canFastenOnCounterbatten: requestDTO.canFastenOnCounterbatten,
        groupName: requestDTO.groupName,
        safetyHookType: requestDTO.safetyHookType,
        standardScrewThickness: requestDTO.standardScrewThickness,
        standardScrewLength: requestDTO.standardScrewLength,
        counterbattenScrewLength: requestDTO.counterbattenScrewLength,
        minimumScrewDepth: requestDTO.minimumScrewDepth,
        siHaDocumentationId: requestDTO.siHaDocumentationId,
        screwType: requestDTO.screwType,
        screwConnectionMustBeDocumented: requestDTO.screwConnectionMustBeDocumented,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getSafetyHookById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-hook-by-id'] ?? 'siha/SafetyHook/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SafetyHookResourceViewModel().fromDTO(dto);
  },
});
