import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import _ from '@glittr/frontend-core/src/utils';
import SafetyAnchorPointResourceModel from '../../generated/types/model/resource/SafetyAnchorPointResourceModel';

export default class SafetyAnchorPointResourceViewModel extends SafetyAnchorPointResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  numberOfImages = 4;

  /** returns true if minimal data is available: date is set and 2 images are uploaded */
  get isMinimalOnLocationDataSet() {
    // InstallationDate must be set
    // no Images must be uploaded
    // // let imageCount = 0;
    // // if (this.documentationImage1Id !== null && this.documentationImage1Id !== undefined) { imageCount += 1; }
    // // if (this.documentationImage2Id !== null && this.documentationImage2Id !== undefined) { imageCount += 1; }
    // // if (this.documentationImage3Id !== null && this.documentationImage3Id !== undefined) { imageCount += 1; }
    // // if (this.documentationImage4Id !== null && this.documentationImage4Id !== undefined) { imageCount += 1; }
    return !!this.installationDate; // // && imageCount >= 0;
  }

  schema = {
    ...queryBuilder.properties({
      safetyHookId: { required: true },
      name: { required: true, maxLength: 255 },
      screwLength: { required: true },
    }),
  };
}
