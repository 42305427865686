// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import IntegrationCallResourceDTO from '../../dto/resource/IntegrationCallResourceDTO';

export default abstract class IntegrationCallResourceModel extends BaseModel<IntegrationCallResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get integrationTaskId() { return this.dto.integrationTaskId; }
  set integrationTaskId(value) { this.dto.integrationTaskId = value; }
  /**
  */
  get integrationTaskName() { return this.dto.integrationTaskName; }
  set integrationTaskName(value) { this.dto.integrationTaskName = value; }
  /**
  * @type {int64}
  */
  get sourceAppSystemId() { return this.dto.sourceAppSystemId; }
  set sourceAppSystemId(value) { this.dto.sourceAppSystemId = value; }
  /**
  */
  get sourceAppSystemName() { return this.dto.sourceAppSystemName; }
  set sourceAppSystemName(value) { this.dto.sourceAppSystemName = value; }
  /**
  */
  get integrationObject() { return this.dto.integrationObject; }
  set integrationObject(value) { this.dto.integrationObject = value; }
  /**
  */
  get integrationObjectSummary() { return this.dto.integrationObjectSummary; }
  set integrationObjectSummary(value) { this.dto.integrationObjectSummary = value; }
  /**
  * @type {int64}
  */
  get integrationStatusId() { return this.dto.integrationStatusId; }
  set integrationStatusId(value) { this.dto.integrationStatusId = value; }
  /**
  */
  get integrationStatusName() { return this.dto.integrationStatusName; }
  set integrationStatusName(value) { this.dto.integrationStatusName = value; }
  /**
  */
  get integrationStatusMessage() { return this.dto.integrationStatusMessage; }
  set integrationStatusMessage(value) { this.dto.integrationStatusMessage = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    value = Number.parseFloat(queries.integrationTaskId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationTaskId = value;
    this.dto.integrationTaskName = queries.integrationTaskName;
    value = Number.parseFloat(queries.sourceAppSystemId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sourceAppSystemId = value;
    this.dto.sourceAppSystemName = queries.sourceAppSystemName;
    this.dto.integrationObject = queries.integrationObject;
    this.dto.integrationObjectSummary = queries.integrationObjectSummary;
    value = Number.parseFloat(queries.integrationStatusId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationStatusId = value;
    this.dto.integrationStatusName = queries.integrationStatusName;
    this.dto.integrationStatusMessage = queries.integrationStatusMessage;
  }
}
