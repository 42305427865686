import app from './global/app.json';
import navigation from './global/navigation.json';
import link from './global/link.json';
import dashboard from './page/dashboard.json';
import siHaDocumentationOverview from './page/documentation-overview.json';
import createOrUpdateDocumentation from './page/create-or-update-documentation.json';
import safetyAnchorPointDetail from './page/safetyAnchorPoint-detail.json';
import siHaDocumentation5 from './page/5.documentation.json';
import mobileDashboard from './page/mobile-dashboard.json';
import mobileSafetyAnchorPointDetail from './page/mobile-safetyAnchorPoint-detail.json';
import profile from './page/profile.json';
import printQRCode from './page/printQRCode.json';

export default {
  app,
  navigation,
  link,
  page: {
    dashboard,
    siHaDocumentationOverview,
    createOrUpdateDocumentation,
    safetyAnchorPointDetail,
    siHaDocumentation5,
    mobileDashboard,
    mobileSafetyAnchorPointDetail,
    profile,
    printQRCode,
  },
};
