import { Route, NavigationGuardNext } from 'vue-router';
import Vue from 'vue';
import { Guard } from '@glittr/frontend-core/src/plugins/guards/guardWrapper';

export default {
  name: 'ShareGuard',
  async routeBeforeEnter(to: Route, from: Route) {
    await Vue.$guards.waitForAppLoad();
    const shareId = to.query.shareId ?? to.params.shareId;
    const roles = Vue.$auth.getClaim('role');
    const isGuest = roles?.includes('Guest');
    if (shareId && (!Vue.$auth.isLoggedIn || isGuest)) {
      await Vue.$auth.login({ shareId });
    }
  },
} as Guard;
