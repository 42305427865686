import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import MeApi from '../generated/api/Me';

export default (service: Servicelayer) => ({
  ...MeApi(service),

  async getMySiHaTerms() {
    await Vue.$auth.refresh();
    const isSihaAgbAccepted = Vue.$auth.getClaim('gl-sihaTerms') === 'Accepted';
    return isSihaAgbAccepted;
  },

});
