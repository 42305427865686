import SafetyHookResourceListResultModel from '../../generated/types/model/resource/SafetyHookResourceListResultModel';

export default class SafetyHookResourceListResultViewModel extends SafetyHookResourceListResultModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get allGroupNames() {
    let groupNames = this.items.map((item) => item.groupName);
    groupNames = [...new Set(groupNames)];
    return groupNames;
  }

  get itemsGrouped() {
    const grouped = {} as Record<string, any[]>;
    this.allGroupNames.forEach((groupName) => {
      this.items.forEach((item) => {
        if (item.groupName === groupName) {
          grouped[groupName!] = grouped[groupName!] ?? [];
          grouped[groupName!].push(item);
        }
      });
    });
    return grouped;
  }
}
