import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/_me/profile',
      name: 'glaromat-profile',
      component: () => import('./views/pages/_me/profile.vue'),
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/:tenant/:workspace/siha',
      name: 'siha-index',
      component: () => import('./views/pages/app/siha/documentations.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/documentations',
      name: 'siha-documentations',
      component: () => import('./views/pages/app/siha/documentations.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/process/createDocumentation',
      name: 'siha-create-documentation',
      component: () => import('./views/pages/app/siha/processes/createOrUpdateDocumentation/_createOrUpdateDocumentation.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/process/updateDocumentation/:id',
      name: 'siha-update-documentation',
      component: () => import('./views/pages/app/siha/processes/createOrUpdateDocumentation/_createOrUpdateDocumentation.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/documentation/:siHaDocumentationId/qrcode',
      name: 'siha-documentation-qrcode',
      component: () => import('./views/pages/app/siha/printQRCode.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/documentation/:siHaDocumentationId/safetyAnchorPoint',
      name: 'siha-new-safetyAnchorPoint',
      component: () => import('./views/pages/app/siha/safetyAnchorPointDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/documentation/:siHaDocumentationId/safetyAnchorPoint/:id',
      name: 'siha-safetyAnchorPoint-detail',
      component: () => import('./views/pages/app/siha/safetyAnchorPointDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/mobile/documentation/:shareId',
      name: 'mobile-siha-dashboard',
      component: () => import('./views/pages/app/siha/mobile/dashboard.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/mobile/documentation/:shareId/safetyAnchorPoint/:id',
      name: 'mobile-safetyAnchorPoint-edit',
      component: () => import('./views/pages/app/siha/mobile/safetyAnchorPointDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/mobile/documentation/:shareId/safetyAnchorPoint',
      name: 'mobile-safetyAnchorPoint-create',
      component: () => import('./views/pages/app/siha/mobile/safetyAnchorPointDetail.vue'),
    },
    {
      path: '/:tenant/:workspace/siha/mobile',
      name: 'siha-mobile-index',
      component: () => import('./views/pages/app/siha/mobile/index.vue'),
    },
  ],
};
