
















import mixins from '@glittr/frontend-core/src/mixins';
import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
import GetSafetyHookLookupRequestViewModel from '../services/viewModel/request/SiHaSafetyHook/GetSafetyHookLookupRequestViewModel';
import Int64LookupResourceViewModel from '../services/viewModel/resource/Int64LookupResourceViewModel';
import SafetyAnchorPointResourceViewModel from '../services/viewModel/resource/SafetyAnchorPointResourceViewModel';

export default mixins(validatable).extend({
  props: {
    label: { type: String, default: '' },
    value: { type: undefined, default: undefined },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    documentationId: { type: Number, default: undefined },
    required: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    model: new SafetyAnchorPointResourceViewModel(),
    safetyHookSelect: [] as Int64LookupResourceViewModel[],
  }),
  async mounted() {
    await this.getSafetyHookTypes(this.documentationId);
  },
  methods: {
    async getSafetyHookTypes(documentationId : number) {
      this.isLoading = true;
      try {
        const req = new GetSafetyHookLookupRequestViewModel();
        req.siHaDocumentationId = documentationId;
        const response = await this.$service.api.siHaSafetyHook.getSafetyHookLookup(req);
        this.safetyHookSelect = response.items;
      } catch (error: any) {
        this.innerErrors = (error?.message as string) ?? error as string;
      }
      this.isLoading = false;
    },
    onInput(newValue: string) {
      this.$emit('input', newValue);
    },
    onBlur(event: Event) {
      this.$emit('blur', event);
    },
  },
});
