// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import SafetyHookResourceDTO from '../../dto/resource/SafetyHookResourceDTO';

export default abstract class SafetyHookResourceModel extends BaseModel<SafetyHookResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get groupName() { return this.dto.groupName; }
  set groupName(value) { this.dto.groupName = value; }
  /**
  */
  get screwType() { return this.dto.screwType; }
  set screwType(value) { this.dto.screwType = value; }
  /**
  */
  get safetyHookType() { return this.dto.safetyHookType; }
  set safetyHookType(value) { this.dto.safetyHookType = value; }
  /**
  * @type {int32}
  */
  get standardScrewThickness() { return this.dto.standardScrewThickness; }
  set standardScrewThickness(value) { this.dto.standardScrewThickness = value; }
  /**
  * @type {int32}
  */
  get standardScrewLength() { return this.dto.standardScrewLength; }
  set standardScrewLength(value) { this.dto.standardScrewLength = value; }
  /**
  * @type {int32}
  */
  get counterbattenScrewLength() { return this.dto.counterbattenScrewLength; }
  set counterbattenScrewLength(value) { this.dto.counterbattenScrewLength = value; }
  /**
  * @type {int32}
  */
  get minimumScrewDepth() { return this.dto.minimumScrewDepth; }
  set minimumScrewDepth(value) { this.dto.minimumScrewDepth = value; }
  /**
  */
  get canFastenOnCounterbatten() { return this.dto.canFastenOnCounterbatten; }
  set canFastenOnCounterbatten(value) { this.dto.canFastenOnCounterbatten = value; }
  /**
  */
  get screwConnectionMustBeDocumented() { return this.dto.screwConnectionMustBeDocumented; }
  set screwConnectionMustBeDocumented(value) { this.dto.screwConnectionMustBeDocumented = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.name = queries.name;
    this.dto.groupName = queries.groupName;
    this.dto.screwType = queries.screwType;
    this.dto.safetyHookType = queries.safetyHookType;
    value = Number.parseFloat(queries.standardScrewThickness);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.standardScrewThickness = value;
    value = Number.parseFloat(queries.standardScrewLength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.standardScrewLength = value;
    value = Number.parseFloat(queries.counterbattenScrewLength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.counterbattenScrewLength = value;
    value = Number.parseFloat(queries.minimumScrewDepth);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.minimumScrewDepth = value;
    value = queries.canFastenOnCounterbatten === 'true' ? true : queries.canFastenOnCounterbatten;
    value = queries.canFastenOnCounterbatten === 'false' ? false : undefined;
    this.dto.canFastenOnCounterbatten = value;
    value = queries.screwConnectionMustBeDocumented === 'true' ? true : queries.screwConnectionMustBeDocumented;
    value = queries.screwConnectionMustBeDocumented === 'false' ? false : undefined;
    this.dto.screwConnectionMustBeDocumented = value;
  }
}
