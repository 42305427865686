




















import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
  }),
  computed: {
    siHaDocumentationShareId(): string | undefined {
      return this.$router.currentRoute?.params?.shareId as string | undefined;
    },
    mobileRootUrl(): string {
      return `/glaromat/il/siha/mobile?shareId=${this.siHaDocumentationShareId}`;
    },
  },
  mounted() {
  },
  methods: {
  },
});
