// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import GetSafetyHookLookupRequestDTO from '../../dto/request/GetSafetyHookLookupRequestDTO';

export default abstract class GetSafetyHookLookupRequestModel extends BaseModel<GetSafetyHookLookupRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  */
  get canFastenOnCounterbatten() { return this.dto.canFastenOnCounterbatten; }
  set canFastenOnCounterbatten(value) { this.dto.canFastenOnCounterbatten = value; }
  /**
  */
  get groupName() { return this.dto.groupName; }
  set groupName(value) { this.dto.groupName = value; }
  /**
  */
  get safetyHookType() { return this.dto.safetyHookType; }
  set safetyHookType(value) { this.dto.safetyHookType = value; }
  /**
  * @type {int32}
  */
  get standardScrewThickness() { return this.dto.standardScrewThickness; }
  set standardScrewThickness(value) { this.dto.standardScrewThickness = value; }
  /**
  * @type {int32}
  */
  get standardScrewLength() { return this.dto.standardScrewLength; }
  set standardScrewLength(value) { this.dto.standardScrewLength = value; }
  /**
  * @type {int32}
  */
  get counterbattenScrewLength() { return this.dto.counterbattenScrewLength; }
  set counterbattenScrewLength(value) { this.dto.counterbattenScrewLength = value; }
  /**
  * @type {int32}
  */
  get minimumScrewDepth() { return this.dto.minimumScrewDepth; }
  set minimumScrewDepth(value) { this.dto.minimumScrewDepth = value; }
  /**
  * @type {int64}
  */
  get siHaDocumentationId() { return this.dto.siHaDocumentationId; }
  set siHaDocumentationId(value) { this.dto.siHaDocumentationId = value; }
  /**
  */
  get screwType() { return this.dto.screwType; }
  set screwType(value) { this.dto.screwType = value; }
  /**
  */
  get screwConnectionMustBeDocumented() { return this.dto.screwConnectionMustBeDocumented; }
  set screwConnectionMustBeDocumented(value) { this.dto.screwConnectionMustBeDocumented = value; }
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get key() { return this.dto.key; }
  set key(value) { this.dto.key = value; }
  /**
  */
  get searchCriteria() { return this.dto.searchCriteria; }
  set searchCriteria(value) { this.dto.searchCriteria = value; }
  /**
  * @type {int32}
  */
  get 'paging.CurrentPage'() { return this.dto['paging.CurrentPage']; }
  set 'paging.CurrentPage'(value) { this.dto['paging.CurrentPage'] = value; }
  /**
  * @type {int32}
  */
  get 'paging.PageSize'() { return this.dto['paging.PageSize']; }
  set 'paging.PageSize'(value) { this.dto['paging.PageSize'] = value; }
  /**
  */
  get 'paging.IncludeCount'() { return this.dto['paging.IncludeCount']; }
  set 'paging.IncludeCount'(value) { this.dto['paging.IncludeCount'] = value; }
  /**
  */
  get 'paging.NoPaging'() { return this.dto['paging.NoPaging']; }
  set 'paging.NoPaging'(value) { this.dto['paging.NoPaging'] = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = queries.canFastenOnCounterbatten === 'true' ? true : queries.canFastenOnCounterbatten;
    value = queries.canFastenOnCounterbatten === 'false' ? false : undefined;
    this.dto.canFastenOnCounterbatten = value;
    this.dto.groupName = queries.groupName;
    this.dto.safetyHookType = queries.safetyHookType;
    value = Number.parseFloat(queries.standardScrewThickness);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.standardScrewThickness = value;
    value = Number.parseFloat(queries.standardScrewLength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.standardScrewLength = value;
    value = Number.parseFloat(queries.counterbattenScrewLength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.counterbattenScrewLength = value;
    value = Number.parseFloat(queries.minimumScrewDepth);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.minimumScrewDepth = value;
    value = Number.parseFloat(queries.siHaDocumentationId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.siHaDocumentationId = value;
    this.dto.screwType = queries.screwType;
    value = queries.screwConnectionMustBeDocumented === 'true' ? true : queries.screwConnectionMustBeDocumented;
    value = queries.screwConnectionMustBeDocumented === 'false' ? false : undefined;
    this.dto.screwConnectionMustBeDocumented = value;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.key = queries.key;
    this.dto.searchCriteria = queries.searchCriteria;
    value = Number.parseFloat(queries['paging.CurrentPage']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.CurrentPage'] = value;
    value = Number.parseFloat(queries['paging.PageSize']);
    value = Number.isNaN(value) ? undefined : value;
    this.dto['paging.PageSize'] = value;
    value = queries['paging.IncludeCount'] === 'true' ? true : queries['paging.IncludeCount'];
    value = queries['paging.IncludeCount'] === 'false' ? false : undefined;
    this.dto['paging.IncludeCount'] = value;
    value = queries['paging.NoPaging'] === 'true' ? true : queries['paging.NoPaging'];
    value = queries['paging.NoPaging'] === 'false' ? false : undefined;
    this.dto['paging.NoPaging'] = value;
  }
}
