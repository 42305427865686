<template>
  <p-app theme="glittr">
    <p-container slot="header" fluid>
      <p-img width="160" contain :src="require('@src/assets/logo.png')"></p-img>
    </p-container>
    <p-container slot="default" fill-height>
      <p-row justify-center>
        <p-col xs12 md10 lg8 xl6>
          <p-card>
            <slot class="content" />
            <p-row>
              <p-col xs6>
                <p-button secondary class="hide-on-print" @click="close()">
                  <p-icon left>
                    close
                  </p-icon> {{ $t('app.close') }}
                </p-button>
              </p-col>
              <p-col xs6 justify-end>
                <p-button class="hide-on-print" @click="print()">
                  <p-icon left>
                    print
                  </p-icon> {{ $t('app.print') }}
                </p-button>
              </p-col>
            </p-row>
          </p-card>
        </p-col>
      </p-row>
    </p-container>
  </p-app>
</template>

<script>
  export default {
    data: () => ({
      theme: 'glittr-pixel-light',
    }),
    methods: {
      async print() {
        window.print();
      },
      async close() {
        window.close();
      },
    },
  };
</script>

<style scoped>
@media print {
  .hide-on-print{display: none;}
}
</style>
