// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import VerifyRequestViewModel from '../../viewModel/request/Me/VerifyRequestViewModel';
import ResendVerificationCodeRequestViewModel from '../../viewModel/request/Me/ResendVerificationCodeRequestViewModel';
import GlaromatUserResourceViewModel from '../../viewModel/resource/GlaromatUserResourceViewModel';
import SetAcceptanceRequestViewModel from '../../viewModel/resource/SetAcceptanceRequestViewModel';
import UserResourceViewModel from '../../viewModel/resource/UserResourceViewModel';
import UserSetPasswordRequestViewModel from '../../viewModel/resource/UserSetPasswordRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getGlaromatUser(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-glaromat-user'] ?? 'Me/GlaromatUser';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GlaromatUserResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateGlaromatUser(model: GlaromatUserResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-glaromat-user'] ?? 'Me';
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async setAcceptance(model: SetAcceptanceRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-acceptance'] ?? 'Me/Accept';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async setProfilePicture(documentId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-profile-picture'] ?? 'Me/ProfilePicture/{documentId}';
    endpointPath = endpointPath.replace(/\{documentId\}/g, `${documentId}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getMe(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-me'] ?? 'core/Me';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new UserResourceViewModel().fromDTO(dto);
  },
  /**  */
  async verify(request: VerifyRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-verify'] ?? 'core/Me/Verify';
    const response = await service.post<any>(endpointPath, {
      query: {
        code: requestDTO.code,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async resendVerificationCode(request: ResendVerificationCodeRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-resend-verification-code'] ?? 'core/Me/ResendVerificationCode';
    const response = await service.post<any>(endpointPath, {
      query: {
        email: requestDTO.email,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async setPassword(model: UserSetPasswordRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-password'] ?? 'core/Me/SetPassword';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
