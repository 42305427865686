import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import GlaromatUserResourceModel from '../../generated/types/model/resource/GlaromatUserResourceModel';

export default class GlaromatUserResourceViewModel extends GlaromatUserResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  schema = {
    ...queryBuilder.properties({
      companyName: { type: ['null', 'string'], maxLength: 255 },
      companyAddress: { type: ['null', 'string'], maxLength: 255 },
      firstName: { type: ['null', 'string'], maxLength: 255 },
      lastName: { type: ['null', 'string'], maxLength: 255 },
    }),
  } as IJSONSchema;
}
