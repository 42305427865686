// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel from '../../viewModel/request/SiHaSafetyAnchorPoint/GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel';
import SafetyAnchorPointListResourcePagingResultViewModel from '../../viewModel/resource/SafetyAnchorPointListResourcePagingResultViewModel';
import SafetyAnchorPointResourceViewModel from '../../viewModel/resource/SafetyAnchorPointResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getSafetyAnchorPointsBySiHaDocumentation(request: GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-anchor-points-by-si-ha-documentation'] ?? 'siha/SafetyAnchorPoint';
    const response = await service.get<any>(endpointPath, {
      query: {
        sihaDocumentationId: requestDTO.sihaDocumentationId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new SafetyAnchorPointListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createSafetyAnchorPoint(model: SafetyAnchorPointResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-safety-anchor-point'] ?? 'siha/SafetyAnchorPoint';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async updateSafetyAnchorPoint(model: SafetyAnchorPointResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-safety-anchor-point'] ?? 'siha/SafetyAnchorPoint';
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async multiplySafetyAnchorPoint(id: number, count: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-multiply-safety-anchor-point'] ?? 'siha/SafetyAnchorPoint/{id}/Multiply/{count}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{count\}/g, `${count}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getSafetyAnchorPoint(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-anchor-point'] ?? 'siha/SafetyAnchorPoint/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SafetyAnchorPointResourceViewModel().fromDTO(dto);
  },
  /**  */
  async deleteSafetyAnchorPoint(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-safety-anchor-point'] ?? 'siha/SafetyAnchorPoint/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async getSafetyAnchorPointImages(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-safety-anchor-point-images'] ?? 'siha/SafetyAnchorPoint/{id}/Images';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
  },
});
