// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import DocumentResourceViewModel from '../../../../viewModel/resource/DocumentResourceViewModel';
import SafetyAnchorPointListResourceDTO from '../../dto/resource/SafetyAnchorPointListResourceDTO';

export default abstract class SafetyAnchorPointListResourceModel extends BaseModel<SafetyAnchorPointListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get remark() { return this.dto.remark; }
  set remark(value) { this.dto.remark = value; }
  /**
  */
  get position() { return this.dto.position; }
  set position(value) { this.dto.position = value; }
  /**
  * @type {int64}
  */
  get safetyHookId() { return this.dto.safetyHookId; }
  set safetyHookId(value) { this.dto.safetyHookId = value; }
  /**
  */
  get screwLength() { return this.dto.screwLength; }
  set screwLength(value) { this.dto.screwLength = value; }
  /**
  */
  get isInstalledOnCounterBatten() { return this.dto.isInstalledOnCounterBatten; }
  set isInstalledOnCounterBatten(value) { this.dto.isInstalledOnCounterBatten = value; }
  /**
  */
  get documentList() { return this.getArrayModels(() => this.dto.documentList, DocumentResourceViewModel)!; }
  set documentList(value) { this.setArrayModels(() => this.dto.documentList, value); }
  /**
  */
  get installationRemark() { return this.dto.installationRemark; }
  set installationRemark(value) { this.dto.installationRemark = value; }
  /**
  * @type {date-time}
  */
  get installationDate() { return this.dto.installationDate; }
  set installationDate(value) { this.dto.installationDate = value; }
  /**
  */
  get isDocumentationCompleted() { return this.dto.isDocumentationCompleted; }
  set isDocumentationCompleted(value) { this.dto.isDocumentationCompleted = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.name = queries.name;
    this.dto.remark = queries.remark;
    this.dto.position = queries.position;
    value = Number.parseFloat(queries.safetyHookId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.safetyHookId = value;
    this.dto.screwLength = queries.screwLength;
    value = queries.isInstalledOnCounterBatten === 'true' ? true : queries.isInstalledOnCounterBatten;
    value = queries.isInstalledOnCounterBatten === 'false' ? false : undefined;
    this.dto.isInstalledOnCounterBatten = value;
    this.dto.installationRemark = queries.installationRemark;
    this.dto.installationDate = queries.installationDate;
    value = queries.isDocumentationCompleted === 'true' ? true : queries.isDocumentationCompleted;
    value = queries.isDocumentationCompleted === 'false' ? false : undefined;
    this.dto.isDocumentationCompleted = value;
  }
}
