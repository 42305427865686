import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import SiHaSafetyAnchorPointApi from '../generated/api/SiHaSafetyAnchorPoint';
import SafetyAnchorPointResourceViewModel from '../viewModel/resource/SafetyAnchorPointResourceViewModel';

export default (service: Servicelayer) => ({
  ...SiHaSafetyAnchorPointApi(service),

  async downloadImage(model: SafetyAnchorPointResourceViewModel, index: string | number) {
    const imageId = (model as any)[`documentationImage${index}Id`];
    if (imageId) {
      const image = await service.api.documents.downloadImage(imageId);
      return image as File;
    }
    return undefined;
  },

  async downloadImages(model: SafetyAnchorPointResourceViewModel) {
    const allPromises = [] as Promise<any>[];
    for (let i = 1; i < model.numberOfImages + 1; i += 1) {
      allPromises.push(this.downloadImage(model, i));
    }
    const imageFiles = await Promise.all(allPromises);
    const images = {} as Record<string | number, File>;
    for (let i = 0; i < imageFiles.length; i += 1) {
      images[i + 1] = imageFiles[i];
    }
    return images;
  },

  async downloadImageArrays(model: SafetyAnchorPointResourceViewModel) {
    const images = await this.downloadImages(model);
    const imageAsArray = {} as Record<string | number, File[]>;
    Object.keys(images).forEach((key) => {
      imageAsArray[key] = images[key] ? [images[key]] : [];
    });
    return imageAsArray;
  },

});
