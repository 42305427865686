import Vue from 'vue';
import SiHaDocumentationListResourceModel from '../../generated/types/model/resource/SiHaDocumentationListResourceModel';

export default class SiHaDocumentationListResourceViewModel extends SiHaDocumentationListResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get siHaDocumentationStatusDisplay() {
    return Vue.$tEnum('page.siHaDocumentationOverview.select.status', this.siHaDocumentationStatus!);
  }
}
