// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSiHaDocumentationsRequestViewModel from '../../viewModel/request/SiHaDocumentation/GetSiHaDocumentationsRequestViewModel';
import SiHaDocumentationListResourcePagingResultViewModel from '../../viewModel/resource/SiHaDocumentationListResourcePagingResultViewModel';
import SiHaDocumentationResourceViewModel from '../../viewModel/resource/SiHaDocumentationResourceViewModel';
import ShareSiHaDocumentationRequestViewModel from '../../viewModel/resource/ShareSiHaDocumentationRequestViewModel';
import SafetyHookResourceListResultViewModel from '../../viewModel/resource/SafetyHookResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getSiHaDocumentations(request: GetSiHaDocumentationsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-si-ha-documentations'] ?? 'siha/SiHaDocumentation';
    const response = await service.get<any>(endpointPath, {
      query: {
        siHaDocumentationStatusId: requestDTO.siHaDocumentationStatusId,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new SiHaDocumentationListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createSiHaDocumentation(model: SiHaDocumentationResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-si-ha-documentation'] ?? 'siha/SiHaDocumentation';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getSiHaDocumentation(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-si-ha-documentation'] ?? 'siha/SiHaDocumentation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SiHaDocumentationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateSiHaDocumentation(id: number, model: SiHaDocumentationResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-si-ha-documentation'] ?? 'siha/SiHaDocumentation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteSiHaDocumentation(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-si-ha-documentation'] ?? 'siha/SiHaDocumentation/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async getSiHaDocumentationByShareId(shareId: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-si-ha-documentation-by-share-id'] ?? 'siha/SiHaDocumentation/share/{shareId}';
    endpointPath = endpointPath.replace(/\{shareId\}/g, `${shareId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SiHaDocumentationResourceViewModel().fromDTO(dto);
  },
  /**  */
  async shareSiHaDocumentation(id: number, model: ShareSiHaDocumentationRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-share-si-ha-documentation'] ?? 'siha/SiHaDocumentation/{id}/Share';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async getSiHaDocumentationSafetyHookList(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-si-ha-documentation-safety-hook-list'] ?? 'siha/SiHaDocumentation/{id}/SafetyHooks';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new SafetyHookResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async downloadSiHaDocumentation(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-si-ha-documentation'] ?? 'siha/SiHaDocumentation/{id}/Download';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
