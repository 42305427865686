<template>
  <p-app theme="glittr">
    <p-container slot="header" fluid>
      <p-img width="160" contain :src="require('@src/assets/logo.png')"></p-img>
    </p-container>
    <p-container slot="default" fill-height>
      <p-row justify-center>
        <p-col xs12 md10 lg8 xl6>
          <p-card>
            <slot class="content" />
          </p-card>
        </p-col>
      </p-row>
    </p-container>
  </p-app>
</template>

<script>
  export default {
    data: () => ({
      theme: 'glittr-pixel-light',
    }),
  };
</script>
