


































































import Vue from 'vue';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import User from '@glittr/frontend-core/src/plugins/auth/user';
import pBreadcrumbButton from '@glittr/frontend-core/src/components/p-breadcrumb/p-breadcrumb-button.vue';
import ilWizardStep from './il-wizard-step.vue';

export interface StepData<TModel = any> {
  title?: string,
  breadcrumb: string,
  schema?: IJSONSchema,
  disablePreviousSteps?: boolean,
  component: () => Promise<any>,
  // eslint-disable-next-line no-unused-vars
  disabled?: (data: TModel)=>boolean | boolean,
  // eslint-disable-next-line no-unused-vars
  condition?: (data: TModel)=>boolean,
  // eslint-disable-next-line no-unused-vars
  sendData?: boolean | ((data: any) => boolean),
  // eslint-disable-next-line no-unused-vars
  clearProgressAfterSend?: boolean | ((data: any) => boolean),
  // eslint-disable-next-line no-unused-vars
  clearProgressOnMounted?: boolean | ((data: any) => boolean),
}

export default Vue.extend({
  components: { pBreadcrumbButton },
  props: {
    title: { type: String, default: undefined },
    saveDataLocally: { type: Boolean, default: false },
    steps: { type: Array, default: () => [] },
    value: { type: Number, default: undefined, validator: (value: number) => value >= 0 },
    initializeData: { type: Function, default: () => ({}) },
    toOnComplete: { type: String, default: '/' },
    loadServiceFunction: { type: Function, default: async () => {} },
    saveServiceFunction: { type: Function, default: async () => {} },
    modelClass: { type: undefined, default: undefined },
    disableNextSteps: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    childSteps: [] as InstanceType<typeof ilWizardStep>[],
    currentStepNr: 0,
    progressNr: 0,
    errorResponse: undefined,
    wizardData: undefined as BaseModel<any> | undefined,
  }),
  computed: {
    classList(): Record<string, boolean> {
      return {

      };
    },
    queryStepNr(): number {
      if (!this.$router.currentRoute?.query?.step) {
        return 0;
      }
      return Number.parseInt(this.$router.currentRoute.query.step as string, 10);
    },
    progressStorageKey(): string {
      return `${this.dataStorageKey}-progress`;
    },
    dataStorageKey(): string {
      const { version } = this.$version;
      const name = this.title;
      const user = this.$auth.user ?? {} as User;
      const userId = user.id ?? 'unknown';
      // Make progress unique to the app version and the user
      return `${version}-${userId}-${name}`;
    },
    currentStepData(): Partial<StepData> {
      if (!this.steps || this.currentStepNr < 0 || this.currentStepNr >= this.steps.length) {
        return {};
      }
      return this.steps[this.currentStepNr] as StepData;
    },
    translatedTitle(): string {
      if (!this.title) {
        return '';
      }
      return this.$tAlt(this.title, this.title);
    },
  },
  watch: {
    'wizardData.dto': {
      handler() {
        if (!this.isLoading) {
          this.$debounce(() => {
            this.currentStepNr = this.getClosestEnabledStep(this.currentStepNr);
          }, 500, this)();
        }
      },
    },
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.currentStepNr = this.value;
        }
      },
    },
    currentStepNr() {
      this.$emit('input', this.currentStepNr);
    },
  },
  async mounted() {
    this.isLoading = true;
    // Make sure the user data is available
    // TODO: User data should always be available
    await this.$auth.getUser();
    this.loadWizardData();
    this.saveWizardData();
    this.checkAndClearProgressOnMount();
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    getClassList(i: number) {
      return { 'p-selection-active': this.currentStepNr === i, active: this.currentStepNr === i };
    },
    closeWizard() {
      this.$router.back();
    },
    /** Gets closest enabled step, if all are disabled it returns the stepNrToTest that was passed */
    getClosestEnabledStep(stepNrToTest: number) {
      if (this.isLoading) {
        return stepNrToTest;
      }
      let stepTestOrder = [] as number[];
      const steps = this.steps as StepData[];
      stepTestOrder.push(stepNrToTest);
      for (let i = 0; i < steps.length; i += 1) {
        stepTestOrder.push(stepNrToTest - (i + 1));
        stepTestOrder.push(stepNrToTest + (i + 1));
      }
      stepTestOrder = stepTestOrder.filter((n) => n >= 0 && n < steps.length);
      for (let i = 0; i < stepTestOrder.length; i += 1) {
        const toTest = stepTestOrder[i];
        const step = steps[toTest];
        if (this.isStepVisible(step) && !this.isStepDisabled(toTest, step)) {
          return toTest;
        }
      }
      return stepNrToTest;
    },
    onChildRegistered(child: Vue) {
      const stepComponent = child.$parent as InstanceType<typeof ilWizardStep>;
      stepComponent.backFunction = this.backOneStep;
      stepComponent.proceedFunction = this.saveAndProceed;
      stepComponent.completeFunction = () => this.completeWizard(this.toOnComplete);
      stepComponent.isFirstStep = this.currentStepNr === 0;
      stepComponent.isBackDisabled = !!stepComponent.isFirstStep || !!this.currentStepData.disablePreviousSteps;
      stepComponent.isLastStep = this.currentStepNr >= this.steps.length - 1;
      stepComponent.stepData = this.currentStepData as StepData;
      this.childSteps.push(stepComponent);
    },
    onChildUnregister(child: Vue) {
      const stepComponent = child.$parent as InstanceType<typeof ilWizardStep>;
      this.childSteps = this.childSteps.filter((step) => step !== stepComponent);
    },
    isStepDisabled(index: number, step: StepData) {
      if (this.isLoading) {
        return true;
      }
      if (typeof step.disabled === 'function') {
        const isDisabled = step.disabled(this.wizardData ?? {});
        return isDisabled;
      }
      if (step.disabled) {
        return step.disabled;
      }
      if (this.progressNr < index && this.disableNextSteps) {
        return true;
      }
      if (this.currentStepData.disablePreviousSteps) {
        if (this.progressNr > index) {
          return true;
        }
      }
      return false;
    },
    isStepVisible(step: StepData) {
      if (typeof step.condition === 'function') {
        const isVisible = step.condition(this.wizardData ?? {});
        return isVisible;
      }
      return true;
    },
    async loadWizardData() {
      this.progressNr = 0;
      this.currentStepNr = 0;
      if (this.queryStepNr) {
        this.setStep(this.queryStepNr);
      }
      if (typeof this.loadServiceFunction === 'function') {
        const data = await this.callLoadServiceFunction();
        this.wizardData = data;
      } else if (this.saveDataLocally) {
        try {
          const ModelClass = this.modelClass as unknown as new () => any;
          const cachedData = this.$sessionStorage.get<{}>(this.dataStorageKey);
          // eslint-disable-next-line no-unused-vars
          if (!ModelClass) {
            console.error('No model-class set! A model-class needs to be supplied to every wizard');
          }
          if (cachedData) {
            const cachedProgress = this.$sessionStorage.get<number>(this.progressStorageKey)!;
            this.setStep(this.queryStepNr ?? cachedProgress ?? 0);
            this.wizardData = new ModelClass().fromDTO(cachedData);
          } else {
            const init = this.initializeData as () => {};
            this.wizardData = new ModelClass().fromModel(init());
          }
        } catch (error) {
          console.error('Unable to load previous wizard data, the progress will be reset.');
          console.error(error);
          // eslint-disable-next-line no-unused-vars
          const ModelClass = this.modelClass as unknown as new () => any;
          const init = this.initializeData as () => {};
          this.wizardData = new ModelClass().fromModel(init());
        }
      } else {
        const init = this.initializeData as () => BaseModel<any>;
        this.wizardData = init();
      }
    },
    saveWizardData() {
      if (this.saveDataLocally) {
        const dto = this.wizardData?.getDTO();
        this.$sessionStorage.set(this.dataStorageKey, dto);
      }
    },
    async callLoadServiceFunction() {
      const init = this.initializeData as () => BaseModel<any>;
      let loadedData = init();
      try {
        this.childSteps.forEach((step) => {
          step.errorResponse = undefined;
          step.isLoading = true;
        });
        // eslint-disable-next-line no-unused-vars
        loadedData = await (this.loadServiceFunction as (stepNr: number, data: any)=>Promise<any>)(this.currentStepNr, this.wizardData);
      } catch (e: any) {
        this.errorResponse = e;
        this.childSteps.forEach((step) => {
          step.errorResponse = e;
          step.isLoading = false;
        });
        return loadedData;
      }
      this.childSteps.forEach((step) => {
        step.isLoading = false;
      });
      return loadedData;
    },
    async callSaveServiceFunction() {
      try {
        this.childSteps.forEach((step) => {
          step.errorResponse = undefined;
          step.isLoading = true;
        });
        // eslint-disable-next-line no-unused-vars
        await (this.saveServiceFunction as (stepNr: number, data: any)=>Promise<any>)(this.currentStepNr, this.wizardData);
      } catch (e) {
        this.childSteps.forEach((step) => {
          step.errorResponse = e;
          step.isLoading = false;
        });
        return false;
      }
      this.childSteps.forEach((step) => {
        step.isLoading = false;
      });
      return true;
    },
    clearProgress() {
      if (this.saveDataLocally) {
        this.$sessionStorage.remove(this.dataStorageKey);
        this.$sessionStorage.remove(this.progressStorageKey);
      }
    },
    async completeWizard(to: string = '/') {
      let shallSendData = this.currentStepData.sendData;
      if (typeof shallSendData === 'function') {
        shallSendData = shallSendData(this.wizardData ?? {});
      }
      if (shallSendData) {
        const success = await this.callSaveServiceFunction();
        if (!success) {
          return;
        }
      }
      this.$emit('completed', this.wizardData);
      let shallClearProgress = this.currentStepData.clearProgressAfterSend;
      if (typeof shallClearProgress === 'function') {
        shallClearProgress = shallClearProgress(this.wizardData ?? {});
      }
      if (shallClearProgress) {
        this.clearProgress();
      }
      this.$router.replace(to);
    },
    async backOneStep() {
      if (this.currentStepData.disablePreviousSteps) {
        return;
      }
      if (this.currentStepNr === 0) {
        return;
      }
      this.setStep(this.currentStepNr - 1);
    },
    async saveAndProceed() {
      if (this.wizardData && this.currentStepData.schema) {
        const isValid = this.wizardData.validateWithSchema(this.currentStepData.schema);
        if (!isValid) {
          return;
        }
      }
      let shallSendData = this.currentStepData.sendData;
      if (typeof shallSendData === 'function') {
        shallSendData = shallSendData(this.wizardData ?? {});
      }
      if (shallSendData) {
        const success = await this.callSaveServiceFunction();
        if (!success) {
          return;
        }
      }
      if (this.currentStepData.clearProgressAfterSend) {
        this.clearProgress();
      } else {
        this.saveWizardData();
      }
      this.setStep(this.currentStepNr + 1);
    },
    onBreadcrumbClick(stepNr: number, step: StepData) {
      if (!this.isStepDisabled(stepNr, step)) {
        this.setStep(stepNr);
      }
    },
    checkAndClearProgressOnMount() {
      let shallClearProgress = this.currentStepData.clearProgressOnMounted;
      if (typeof shallClearProgress === 'function') {
        shallClearProgress = shallClearProgress(this.currentStepData ?? {});
      }
      if (shallClearProgress) {
        this.clearProgress();
      }
    },
    setStep(stepNr: number) {
      const steps = this.steps as StepData[];
      this.childSteps.forEach((step) => {
        step.errorResponse = undefined;
      });
      let computedStepNr = Math.min(steps.length - 1, stepNr);
      computedStepNr = this.getClosestEnabledStep(computedStepNr);

      this.$set(this, 'currentStepNr', computedStepNr);
      if (this.wizardData) {
        this.wizardData.errors = [];
      }
      if (this.currentStepNr > this.progressNr) {
        this.$set(this, 'progressNr', this.currentStepNr);
        if (this.saveDataLocally) {
          // User moved further in the process, save the furthest step
          this.$sessionStorage.set(this.progressStorageKey, this.currentStepNr);
        }
      }

      const currentPath = window.location.pathname;
      let newPath = currentPath;
      if (currentPath.includes('?')) {
        newPath += `&step=${computedStepNr}`;
      } else {
        newPath += `?step=${computedStepNr}`;
      }
      window.history.replaceState(window.location.origin, '', newPath);

      this.checkAndClearProgressOnMount();
    },
  },
});
