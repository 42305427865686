






























































import Vue from 'vue';

export default Vue.extend({
  auth: true,
  data: () => ({
    profileMenuVisible: false,
    isSidedrawerVisible: true,
    isSihaAgbAccepted: false,
  }),
  computed: {
    user() {
      return this.$auth.user ?? {};
    },
    isAdmin() {
      let isAdmin = false;
      if (this.$auth.isLoggedIn) {
        isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
      }
      return isAdmin;
    },
  },
  async mounted() {
    this.isSihaAgbAccepted = await this.$service.api.me.getMySiHaTerms();
  },
  methods: {
    async doLogoff() {
      try {
        await this.$auth.logout();
        this.$router.push('/_exterior/login');
      } catch (error) {
        this.$log.error('Unable to log out an error occurred');
        this.$log.error(error);
      }
    },
    checkSiHaAGB() {
      if (this.isSihaAgbAccepted) {
        this.$router.push('/glaromat/il/siha');
      }
    },
  },
});
