// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import SiHaDocumentationStatusEnumResourceViewModel from '../../../../viewModel/resource/SiHaDocumentationStatusEnumResourceViewModel';
import SiHaDocumentationResourceDTO from '../../dto/resource/SiHaDocumentationResourceDTO';

export default abstract class SiHaDocumentationResourceModel extends BaseModel<SiHaDocumentationResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {uuid}
  */
  get shareId() { return this.dto.shareId; }
  set shareId(value) { this.dto.shareId = value; }
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  * @type {int32}
  */
  get siHaDocumentationStatus() { return this.dto.siHaDocumentationStatus; }
  set siHaDocumentationStatus(value) { this.dto.siHaDocumentationStatus = value; }
  /**
  * @type {int64}
  */
  get constructionPlanDocumentId() { return this.dto.constructionPlanDocumentId; }
  set constructionPlanDocumentId(value) { this.dto.constructionPlanDocumentId = value; }
  /**
  * @type {int64}
  */
  get locationMapDocumentId() { return this.dto.locationMapDocumentId; }
  set locationMapDocumentId(value) { this.dto.locationMapDocumentId = value; }
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get remark() { return this.dto.remark; }
  set remark(value) { this.dto.remark = value; }
  /**
  */
  get projectNumber() { return this.dto.projectNumber; }
  set projectNumber(value) { this.dto.projectNumber = value; }
  /**
  */
  get clientCompanyName() { return this.dto.clientCompanyName; }
  set clientCompanyName(value) { this.dto.clientCompanyName = value; }
  /**
  */
  get clientAddressLine() { return this.dto.clientAddressLine; }
  set clientAddressLine(value) { this.dto.clientAddressLine = value; }
  /**
  */
  get clientPostalCode() { return this.dto.clientPostalCode; }
  set clientPostalCode(value) { this.dto.clientPostalCode = value; }
  /**
  */
  get clientCity() { return this.dto.clientCity; }
  set clientCity(value) { this.dto.clientCity = value; }
  /**
  */
  get clientFirstName() { return this.dto.clientFirstName; }
  set clientFirstName(value) { this.dto.clientFirstName = value; }
  /**
  */
  get clientLastName() { return this.dto.clientLastName; }
  set clientLastName(value) { this.dto.clientLastName = value; }
  /**
  */
  get clientEMail() { return this.dto.clientEMail; }
  set clientEMail(value) { this.dto.clientEMail = value; }
  /**
  */
  get clientPhoneNumber() { return this.dto.clientPhoneNumber; }
  set clientPhoneNumber(value) { this.dto.clientPhoneNumber = value; }
  /**
  */
  get addressLine() { return this.dto.addressLine; }
  set addressLine(value) { this.dto.addressLine = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  * @type {date-time}
  */
  get nextInspectionDueDate() { return this.dto.nextInspectionDueDate; }
  set nextInspectionDueDate(value) { this.dto.nextInspectionDueDate = value; }
  /**
  */
  get safetyHookIds() { return this.dto.safetyHookIds; }
  set safetyHookIds(value) { this.dto.safetyHookIds = value; }
  /**
  */
  get isSiHaDocumentationEditable() { return this.dto.isSiHaDocumentationEditable; }
  set isSiHaDocumentationEditable(value) { this.dto.isSiHaDocumentationEditable = value; }
  /**
  */
  get isSiHaDocumentationPrintable() { return this.dto.isSiHaDocumentationPrintable; }
  set isSiHaDocumentationPrintable(value) { this.dto.isSiHaDocumentationPrintable = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(queries.creatorId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = queries.creatorName;
    this.dto.created = queries.created;
    value = Number.parseFloat(queries.modifierId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = queries.modifierName;
    this.dto.modified = queries.modified;
    this.dto.shareId = queries.shareId;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    value = Number.parseFloat(queries.constructionPlanDocumentId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.constructionPlanDocumentId = value;
    value = Number.parseFloat(queries.locationMapDocumentId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.locationMapDocumentId = value;
    this.dto.title = queries.title;
    this.dto.remark = queries.remark;
    this.dto.projectNumber = queries.projectNumber;
    this.dto.clientCompanyName = queries.clientCompanyName;
    this.dto.clientAddressLine = queries.clientAddressLine;
    this.dto.clientPostalCode = queries.clientPostalCode;
    this.dto.clientCity = queries.clientCity;
    this.dto.clientFirstName = queries.clientFirstName;
    this.dto.clientLastName = queries.clientLastName;
    this.dto.clientEMail = queries.clientEMail;
    this.dto.clientPhoneNumber = queries.clientPhoneNumber;
    this.dto.addressLine = queries.addressLine;
    this.dto.city = queries.city;
    this.dto.postalCode = queries.postalCode;
    this.dto.nextInspectionDueDate = queries.nextInspectionDueDate;
    value = queries.isSiHaDocumentationEditable === 'true' ? true : queries.isSiHaDocumentationEditable;
    value = queries.isSiHaDocumentationEditable === 'false' ? false : undefined;
    this.dto.isSiHaDocumentationEditable = value;
    value = queries.isSiHaDocumentationPrintable === 'true' ? true : queries.isSiHaDocumentationPrintable;
    value = queries.isSiHaDocumentationPrintable === 'false' ? false : undefined;
    this.dto.isSiHaDocumentationPrintable = value;
  }
}
